import { merge } from 'lodash'
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Spinner, Stack } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { StrictOmit } from 'ts-essentials'
import {
  Permissions,
  CaiInstance,
  Organization,
  useGetCaiInstanceQuery,
  useGetMeQuery,
  User,
  WorkingRole,
} from '../../gql/graphql'
import { isExpired } from '../../utils/auth'

type UserInfo = StrictOmit<User, 'roles'> & {
  caiInstance?: CaiInstance | null
  organization?: Organization
  permissions?: Permissions[]
  roles?: WorkingRole[]
}

type States = {
  auth?: { token: string }
  userInfo?: UserInfo
}

type Actions = {
  login: (token: string) => void
  logout: () => void
  setUserInfo: Dispatch<SetStateAction<UserInfo | undefined>>
}

export type AuthContextType = States & Actions

export const AuthContext = createContext<AuthContextType>({
  auth: undefined,
  userInfo: undefined,

  login: () => {},
  logout: () => {},
  setUserInfo: () => {},
})

export const AuthProvider = ({ children }: PropsWithChildren) => {
  // utils
  const { t } = useTranslation()
  const history = useHistory()
  const accessToken = localStorage.getItem('accessToken')
  const storageOrgId = localStorage.getItem('organizationId')

  // states
  const [auth, setAuth] = useState<States['auth']>(
    accessToken ? { token: accessToken } : undefined,
  )
  const [userInfo, setUserInfo] = useState<States['userInfo']>()

  // apis
  const { data, loading } = useGetMeQuery({
    skip: !auth?.token,
    fetchPolicy: 'no-cache',
    onError: (error) => {
      logout()
      history.push('/sign-in')
      toast.error(
        t(
          'auth.authProvider.toast.error',
          'Session has expired, please log back in.',
        ),
      )
      console.error(error)
    },
    onCompleted: (data) => {
      if (!data.me) {
        toast.error(
          t(
            'auth.authProvider.toast.userError',
            'Session has expired, please log back in.',
          ),
        )
      }
    },
  })

  const userId = data?.me?.id ?? ''

  useGetCaiInstanceQuery({
    variables: { userId },
    skip: !userId,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (userInfo) {
        setUserInfo({ ...userInfo, caiInstance: data.caiInstance })
      }
    },
    onError: (error) => {
      toast.error(
        t(
          'common.toast.error',
          'Something went wrong. Please try again later.',
        ),
      )
      console.error(error)
    },
  })

  // funcs
  const login = (token: string) => {
    if (!token || isExpired(token)) return
    setAuth({ token })
    localStorage.setItem('accessToken', token)
    history.push('/')
  }

  const logout = () => {
    setAuth(undefined)
    setUserInfo(undefined)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('organizationId')
  }

  useEffect(() => {
    if (!data?.me) return

    const { userOrganizations = [], roles: permissions, ...rest } = data.me

    if (!userOrganizations?.length) {
      toast.error(
        t(
          'common.toast.error',
          'Something went wrong. Please try again later.',
        ),
      )
    }
    const sortedUserOrg = userOrganizations?.sort((a, b) => {
      const nameA = a.organization.name || ''
      const nameB = b.organization.name || ''
      return nameA.localeCompare(nameB)
    })

    const activeOrganization = sortedUserOrg?.find((userOrg) => {
      return storageOrgId
        ? userOrg.organization.id === storageOrgId
        : sortedUserOrg[0]
    })?.organization
    const orgRoles = permissions?.find(
      (p) => p?.organizationId === activeOrganization?.id,
    )?.workingRoles

    const newData = {
      ...rest,
      userOrganizations: sortedUserOrg,
      organization: activeOrganization,
      roles: orgRoles,
      permissions,
    }

    setUserInfo((state) => merge(state, newData))
  }, [JSON.stringify(data?.me)])

  if (loading) {
    return (
      <Stack style={{ height: '100vh' }}>
        <Spinner style={{ margin: 'auto' }} variant="primary" />
      </Stack>
    )
  }

  const value: AuthContextType = {
    auth,
    userInfo,
    login,
    logout,
    setUserInfo,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
