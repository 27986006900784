import parse from 'html-react-parser'
import { marked } from 'marked'
import { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { CopyButton } from 'src/components/buttons'
import ImageViewer from '../common/ImageViewer'

export default function ChatBubble({ data }) {
  const { t } = useTranslation()
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered((state) => !state)
  const wordsToHighlight = [t('common.word.communcation')] // Add
  const regex = new RegExp(`\\b(${wordsToHighlight.join('|')})\\b`, 'gi')
  const formattedMessage = data.message?.replace(regex, '<strong>$1</strong>')

  return (
    <div
      className="border-0 p-3 p-md-3 position-relative"
      style={{
        maxWidth: '100%',
        textAlign: 'left',
        background: 'var(--bg-chat-bubble)',
        boxShadow: 'var(--chat-shadow)',
        borderRadius: '0 25px 25px 25px',
        width: 'fit-content',
        ...(data.type !== 'received' && {
          background: 'var(--bg-chat-bubble-send)',
          borderRadius: '25px 0 25px 25px',
        }),
        ...(isMobileOnly && { fontSize: 15 }),
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseEnter}
    >
      <div className="chat-message">
        {parse(marked.parse(formattedMessage?.replace(/\\n/g, '<br/>')))}

        <p className="mb-0">
          {data.images?.map((d, i) => (
            <ImageViewer
              key={i}
              alt={`image ${i}`}
              className="w120 img-thumbnail"
              src={d}
            />
          ))}
        </p>
      </div>

      {data.type === 'received' && isHovered && (
        <CopyButton
          placement="right"
          style={{ position: 'absolute', top: 3, right: -40 }}
          text={data.message?.replace(/\\n/g, '\n')}
        />
      )}
    </div>
  )
}
