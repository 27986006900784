import { Flex } from 'antd'
import { useContext, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { CategoryForm } from 'src/components/Category'
import Tooltip from 'src/components/UI/Tooltip'
import { Button, IconButton } from 'src/components/buttons'
import { AuthContext } from '../../components/Auth/AuthProvider'
import PageHeader from '../../components/common/PageHeader'
import {
  Category,
  GetCategoriesDocument,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
} from '../../gql/graphql'
import { usePagination } from '../../hooks/usePagination'
import { toast } from 'react-toastify'
import DeleteButton from 'src/components/common/DeleteButton'

const CategoryList = () => {
  const { t } = useTranslation()

  const { userInfo } = useContext(AuthContext)
  const { currentPage, pageSize, handlePageChange, handleRowsPerPageChange } =
    usePagination()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null,
  )

  const { data, loading } = useGetCategoriesQuery({
    variables: { currentPage, pageSize },
    onError: () => {
      toast.error(t('resources.category.fields.loadingErr'))
    },
  })

  const [deleteCategory, { loading: deleteLoading }] =
    useDeleteCategoryMutation()

  const handleDelete = async (categoryId: string) => {
    try {
      await deleteCategory({
        variables: {
          categoryId,
        },
        refetchQueries: [GetCategoriesDocument],
      })
      toast.success(
        t('notification.delete.success', {
          name: t('resources.category.name', 'Category'),
        }),
      )
    } catch (error) {
      toast.error(t('common.toast.error'))
    }
  }

  const columns: TableColumn<Category>[] = [
    {
      name: t('common.word.title'),
      selector: (row) => row.name,
    },
    {
      name: t('common.word.type'),
      selector: (row) => row.type,
    },
    {
      name: '',
      cell: (row) => {
        return (
          <Flex
            wrap
            gap="small"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip
              message={t('action.update', 'Edit', {
                name: t('resources.category.name', 'Category'),
              })}
            >
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  setSelectedCategory(row)
                  setIsOpenModal(true)
                }}
              >
                <i
                  className="icofont-edit"
                  style={{
                    color: 'var(--color-primary)',
                  }}
                ></i>
              </button>
            </Tooltip>
            <Tooltip
              message={t('action.delete', 'Delete', {
                name: t('resources.category.name', 'Category'),
              })}
            >
              <DeleteButton
                disabled={deleteLoading}
                modalBody={t(
                  'resources.category.fields.deleteCategory',
                  'Are you sure you want to delete this category?',
                )}
                modalHeader={t('action.delete', 'Delete', {
                  name: t('resources.category.name', 'Category'),
                })}
                onDelete={() => handleDelete(row.id)}
              />
            </Tooltip>

            {/* <IconButton /> */}
          </Flex>
        )
      },
    },
  ]

  return (
    <div className="container-xxl">
      {isOpenModal && (
        <CategoryForm
          record={selectedCategory}
          onClose={() => setIsOpenModal(false)}
        />
      )}

      <PageHeader
        headerTitle={t('hr.programForm.form.categories')}
        renderRight={() => (
          <div className="col-auto d-flex w-sm-100">
            <Button
              icon={<i className="icofont-plus-circle me-2 fs-6"></i>}
              label={t('it.dashboard.buttons.createCategory')}
              style={{ whiteSpace: 'nowrap', marginRight: '10px' }}
              onClick={() => {
                setIsOpenModal(true)
                setSelectedCategory(null)
              }}
            />
          </div>
        )}
      />

      <DataTable
        pagination
        paginationServer
        columns={columns}
        data={data?.categories?.categories as Category[]}
        defaultSortFieldId={1}
        paginationTotalRows={data?.categories?.totalItems || 0}
        progressPending={loading || deleteLoading}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  )
}

export default CategoryList
