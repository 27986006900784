import 'regenerator-runtime'

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SpeechRecognition from 'react-speech-recognition'
import { Icon } from '@iconify/react'

export default function STTButton(props) {
  const {
    setText,
    passiveColourCode = 'var(--color-primary)',
    transcript,
    browserSupportsSpeechRecognition,
    resetTranscript,
    awaitingResponse,
    height = '36',
    width = '36',
  } = props
  const { i18n } = useTranslation()
  const [listening, setListening] = useState(false)

  const manuallyStopped = useRef(false) // Track if stopped manually

  const startListening = () => {
    manuallyStopped.current = false
    var ttsLanguage
    if (i18n.language === 'fr') {
      ttsLanguage = 'fr-FR'
    } else if (i18n.language === 'es') {
      ttsLanguage = 'es-ES'
    } else {
      ttsLanguage = 'en-US'
    }

    SpeechRecognition.startListening({
      continuous: true,
      language: ttsLanguage,
    })
  }

  const stopListening = () => {
    manuallyStopped.current = true
    SpeechRecognition.stopListening()
    resetTranscript()
  }

  const onClick = () => {
    if (listening) {
      stopListening()
    } else {
      startListening()
    }
    setListening(!listening)
  }

  useEffect(() => {
    const handleEnd = () => {
      if (!manuallyStopped.current) {
        startListening() // Restart if not manually stopped
      }
    }

    SpeechRecognition.onend = handleEnd // Restart on end event

    return () => {
      SpeechRecognition.onend = null // Clean up listener
    }
  }, [])

  useEffect(() => {
    if (listening && transcript) {
      setText?.(transcript)
    }
  }, [transcript])

  if (!browserSupportsSpeechRecognition) {
    console.warn("Browser doesn't support speech recognition.")
    return null
  }

  return (
    <Icon
      color={listening ? 'red' : passiveColourCode}
      disabled={awaitingResponse}
      height={height}
      icon="f7:mic"
      type="button"
      width={width}
      onClick={onClick}
    />
  )
}
