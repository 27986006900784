import React, { forwardRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import Tooltip, { TooltipProps } from '../../UI/Tooltip'

type Props = {
  label: string | React.ReactNode
  handleClick: () => void
  style?: React.CSSProperties
  tooltipProps?: TooltipProps
  className?: string
}

const HeaderButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { handleClick, label, tooltipProps, style, ...rest } = props

  return (
    <Tooltip
      disabled={isMobileOnly}
      overlayTriggerProps={{ placement: 'bottom' }}
      {...tooltipProps}
    >
      <button
        {...rest}
        ref={ref}
        style={{
          fontSize: 13,
          border: '2px solid black',
          borderColor: 'var(--color-primary)',
          fontWeight: 500,
          ...style,
        }}
        onClick={handleClick}
      >
        {label}
      </button>
    </Tooltip>
  )
})

export default HeaderButton
