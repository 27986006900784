import { Icon } from '@iconify/react'
import { Col, Row, Space } from 'antd'
import { debounce } from 'lodash'
import { Dispatch, Fragment, RefObject, SetStateAction } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface StandardChoice {
  label: string
  message: string
  sub_buttons?: StandardChoice[]
}
interface BubbleChoiceProp<T> {
  choices: T[]
  onMessage?: (msg: string, hidden: boolean) => void
  setBubbleChoices?: Dispatch<SetStateAction<T[]>>
  onClick?: (choice: T) => void
  ref?: RefObject<HTMLDivElement>
  disabled?: boolean
}

interface ChoiceDivProps {
  disabled?: boolean
}

export const ChoiceDiv = styled.div<ChoiceDivProps>`
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition:
    background-color 0.3s,
    color 0.3s;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;

  background-color: ${({ disabled }) => (disabled ? '#f0f0f0' : 'transparent')};
  color: ${({ disabled }) => (disabled ? '#a0a0a0' : 'var(--color-primary)')};

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? '#f0f0f0' : 'var(--color-primary)'};
    color: ${({ disabled }) => (disabled ? '#a0a0a0' : 'white')};
  }
  @media (max-width: 600px) {
    font-size: 0.8rem;
  }

  @media (max-width: 400px) {
    font-size: 0.6rem;
  }
`

export const ChoiceContent = ({ label }: { label: string }) => {
  const { t } = useTranslation()

  return (
    <>
      {t(label)}
      <Icon height="20" icon="mynaui:arrow-right" width="20" />
    </>
  )
}

export const BubbleChoices = <T extends StandardChoice>({
  onMessage,
  choices,
  setBubbleChoices,
  onClick,
  ref,
  disabled,
}: BubbleChoiceProp<T>) => {
  const { t } = useTranslation()

  const handleBubbleClick = debounce((choice: T) => {
    if (onClick) {
      onClick(choice)
      return
    }

    if (setBubbleChoices && onMessage) {
      const { message: bubbleMsg, sub_buttons = [] } = choice
      const message = t(bubbleMsg)
      onMessage(message, false)
      setBubbleChoices(sub_buttons as T[])
    }
  }, 500)

  return (
    <>
      {isMobile ? (
        <Row
          gutter={[4, 6]}
          style={{
            width: '100%',
          }}
        >
          {choices.map((choice) => {
            const { label } = choice
            return (
              <Col span={12}>
                <ChoiceDiv
                  disabled={disabled}
                  onClick={() => handleBubbleClick(choice)}
                >
                  <ChoiceContent label={label} />
                </ChoiceDiv>
              </Col>
            )
          })}
        </Row>
      ) : (
        <Space
          ref={ref}
          wrap
          style={{
            height: 'auto',
            background: '#F9F8FF',
            padding: '3px',
          }}
        >
          {choices.map((choice) => {
            const { label } = choice
            return (
              <ChoiceDiv
                disabled={disabled}
                onClick={() => handleBubbleClick(choice)}
              >
                <ChoiceContent label={label} />
              </ChoiceDiv>
            )
          })}
        </Space>
      )}
    </>
  )
}
