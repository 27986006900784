import { UploadOutlined } from '@ant-design/icons'
import { Button, Space, Upload } from 'antd'
import { FC, ReactElement, cloneElement, isValidElement } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { validateNotEmpty } from './utils'

interface UploadInputProps {
  required?: boolean
  source: string
  optionName?: string
  optionValue?: string
  disabled?: boolean
  validate?: (value: string[]) => string
  label?: string
  placeholder?: string
  accept?: string
  labelRender?: ReactElement
}

export const UploadInput: FC<UploadInputProps> = (props) => {
  const {
    source,
    disabled = false,
    validate,
    label = '',
    required = false,
    accept = '',
    labelRender,
  } = props
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {isValidElement(labelRender) ? (
        cloneElement(labelRender)
      ) : (
        <label className="form-label">
          {t(label)}
          {required && <span className="text-danger"> *</span>}
        </label>
      )}
      <Controller
        control={control}
        name={source}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          const handleUploadChange = (info: any) => {
            const filteredFiles = info.fileList
            onChange(filteredFiles)
          }

          return (
            <div>
              <Upload
                accept={accept}
                beforeUpload={() => false}
                disabled={disabled}
                fileList={value || []}
                onChange={handleUploadChange}
              >
                <Button disabled={disabled} icon={<UploadOutlined />}>
                  {t('upload')}
                </Button>
              </Upload>
              {error && <div style={{ color: 'red' }}>{error.message}</div>}
            </div>
          )
        }}
        rules={{
          validate: (value) => {
            if (required) {
              return validate ? validate(value) : validateNotEmpty(value)
            }
            return true
          },
        }}
      />
    </Space>
  )
}
