import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import EN from '../i18n/en.json'
import ES from '../i18n/es.json'
import FR from '../i18n/fr.json'

const resources = {
  en: { translation: EN },
  fr: { translation: FR },
  es: { translation: ES },
}

void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({ resources, fallbackLng: 'en' })

export default i18n
