import { Icon } from '@iconify/react'
import { ConfigProvider, Divider, Form, Radio, Select, Space } from 'antd'
import { FormInstance } from 'antd/lib'
import get from 'lodash/get'
import { FC, useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'src/components/Auth/AuthProvider'
import { CAI_CHAT_FLOWS } from 'src/constants/cai-chat.constant'
import {
  Team,
  User,
  useGetEmployeesQuery,
  useGetTeamsQuery,
} from 'src/gql/graphql'

const { Option } = Select

interface FeedFwdModalProp {
  form: FormInstance
  flow?: string
  currentFeedback: string
}

const FeedFwdCanvasHeader: FC<FeedFwdModalProp> = ({ form, flow }) => {
  // const [form] = Form.useForm()
  const { userInfo, auth } = useContext(AuthContext)
  const { t } = useTranslation()
  const organizationId = userInfo?.organization?.id
  const roleId = get(userInfo, 'employeeDetails.role.id', '')
  const skip = !userInfo || flow !== CAI_CHAT_FLOWS.FEEDBACK

  const { data: employees } = useGetEmployeesQuery({
    variables: { roleId, organizationId },
    skip,
  })

  const { data: teams } = useGetTeamsQuery({
    variables: { organizationId },
    skip,
  })

  return (
    <Form
      form={form}
      initialValues={{
        feedbackTarget: 'person',
      }}
      style={{
        marginBottom: '15px',
        paddingLeft: '5px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        // justifyContent: isMobile ? 'space-between' : 'flex-start',
      }}
    >
      <Space direction={isMobile ? 'vertical' : 'horizontal'}>
        <div
          className="font-weight-bold "
          style={{
            color: '#3C3587',
          }}
        >
          <Icon
            height="24"
            icon="material-symbols:chat-paste-go-outline"
            width="24"
          />
        </div>
      </Space>
      {!isMobile && (
        <Divider
          type={'vertical'}
          style={{
            backgroundColor: 'var(--color-primary)',
            height: '25px',
            marginRight: '20px',
          }}
        />
      )}
      <Space
        direction="horizontal"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: 'var(--color-primary)',
              borderRadius: 15,
            },
            components: {
              Radio: {
                buttonSolidCheckedBg: 'var(--color-primary)',
                buttonSolidCheckedHoverBg: 'var(--color-primary)',
              },
            },
          }}
        >
          <Form.Item
            name="feedbackTarget"
            style={{ marginBottom: 0, width: 'auto' }}
          >
            <Radio.Group
              buttonStyle="solid"
              optionType="button"
              options={[
                {
                  label: t('caiPopup.feedback.person', 'Person'),
                  value: 'person',
                },
                {
                  label: t('caiPopup.feedback.team', 'Team'),
                  value: 'team',
                },
              ]}
              style={{
                backgroundColor: 'var(--feedback-color)',
              }}
              onChange={() => form.setFieldValue('targetId', undefined)}
            />
          </Form.Item>
        </ConfigProvider>

        <Form.Item
          dependencies={['feedbackTarget']}
          style={{
            marginBottom: 0,
            maxWidth: isMobile ? '150px' : '100%',
            width: '400px',
          }}
        >
          {({ getFieldValue, setFieldValue }) => {
            return getFieldValue('feedbackTarget') === 'person' ? (
              <Form.Item name="targetId" style={{ marginBottom: 0 }}>
                <Select
                  allowClear
                  showSearch
                  disabled={getFieldValue('feedbackTarget') !== 'person'}
                  optionFilterProp="label"
                  placeholder="Select a person"
                  onChange={(v) => {
                    const selectedOption = employees?.employees?.find(
                      (emp) => emp?.stsUserId === v,
                    )
                    if (selectedOption) {
                      const {
                        firstName = '',
                        middleName = '',
                        lastName = '',
                      } = selectedOption.employeeDetails || {}
                      const label = `${firstName || ''} ${middleName || ''} ${lastName || ''}`
                      setFieldValue('targetName', label)
                    }
                  }}
                >
                  {employees?.employees?.map((emp) => {
                    const { id, stsUserId, employeeDetails, email } =
                      emp as User
                    const {
                      firstName = '',
                      middleName = '',
                      lastName = '',
                    } = employeeDetails || {}
                    return (
                      <Option
                        key={id}
                        label={`${firstName || ''} ${middleName || ''} ${lastName || ''} - ${email}`}
                        value={stsUserId}
                      >
                        {`${firstName || ''} ${middleName || ''} ${lastName || ''} - ${email}`}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item name="targetId" style={{ marginBottom: 0 }}>
                <Select
                  allowClear
                  showSearch
                  disabled={getFieldValue('feedbackTarget') !== 'team'}
                  optionFilterProp="label"
                  placeholder="Select a team"
                  onChange={(v) => {
                    const selectedOption = teams?.teams?.find(
                      (team) => team?.id === v,
                    )
                    if (selectedOption) {
                      setFieldValue('targetName', selectedOption.name)
                    }
                  }}
                >
                  {teams?.teams?.map((t) => {
                    const { id, name } = t as Team
                    return (
                      <Option key={id} label={name} value={id}>
                        {name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            )
          }}
        </Form.Item>
      </Space>
    </Form>
  )
}

export default FeedFwdCanvasHeader
