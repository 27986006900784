import Cookies from 'js-cookie'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SpeechRecognition from 'react-speech-recognition'
import { toast } from 'react-toastify'
import 'regenerator-runtime'
import { ChatInput } from 'src/components/inputs'
import { CAI_CHAT_FLOWS } from '../../constants/cai-chat.constant'
import { CaiPopupContext } from '../../context/CaiPopupContext'
import { getDaySentiment, submitSentiment } from '../../utils/platform'
import { AuthContext } from '../Auth/AuthProvider'
import Feedback from '../CaiPopup/Feedback/FeedFwd'
import Librarian from '../CaiPopup/Librarian'
import Session from '../CaiPopup/Session'
import TTSPlayer from '../TTSPlayer'
import './Chat.style.scss'
import ChatBubbleChoices from './ChatBubbleChoices'
import MessagesHistory from './MessagesHistory'
import ReportButton from './ReportButton'

function ChatAgent(props) {
  const {
    newChat,
    messages,
    roleName,
    awaitingResponse,
    setAwaitingResponse,
    onInputTyping,
    moodButtonsEnabled,
    currentBubbles,
    onBubblesClick,
    onSendMessage,
    flow,
    setFlow,
    setMessagesMap,
    onReport,
    fullScreen,
    bubblesLoading,
    initialFeedback,
    setInitialFeedback,
    initialFeedbackLoading,
    agentScope,
    tourOpen = false,
  } = props

  const { t } = useTranslation()

  const { auth, userInfo } = useContext(AuthContext)
  const { agentChatRef } = useContext(CaiPopupContext)

  // states
  const [txtMessage, setTxtMessage] = useState('')
  const [avatar, setAvatar] = useState('icofont-user-alt-3')
  const [showMoodButton, setShowMoodButton] = useState(false)
  const [isUserScrolling, setIsUserScrolling] = useState(false)

  // funcs
  const onChangeMessageText = (e) => {
    setTxtMessage(e)
    setShowMoodButton(false)
    onInputTyping()
    Cookies.set('showMoodButton', false, { expires: 1 })
  }

  // Trigger message prompting
  const onMessage = (msg, hidden = false) => {
    const m = (msg ?? txtMessage).trim()
    if (!m) return

    if (flow !== CAI_CHAT_FLOWS.LIBRARIAN) {
      setTxtMessage('')
    }

    setAwaitingResponse(true)
    SpeechRecognition.stopListening()
    onSendMessage(m, hidden).catch((e) => {
      console.error(e)
      toast.error('Error sending message.')
    })
  }

  const handleUserScroll = () => {
    const chatHistory = document.getElementById('chatHistory')
    // If the user is near the bottom (within 100px of the bottom), enable autoscroll
    if (
      chatHistory.scrollHeight -
        chatHistory.scrollTop -
        chatHistory.clientHeight <
      100
    ) {
      setIsUserScrolling(false)
    } else {
      setIsUserScrolling(true)
    }
  }

  const handleMoodButtonClick = (id) => {
    setShowMoodButton(false)
    const sentimentMapping = {
      grinning: 0.75,
      thinking: 0.5,
      pensive: 0.25,
    }
    const sentiment = sentimentMapping[id]
    const day = new Date().toISOString().split('T')[0]
    submitSentiment(auth?.token, sentiment, day, userInfo?.organization?.id)
    Cookies.set('showMoodButton', false, { expires: 1 })
  }

  // Role change behaviour
  useEffect(() => {
    switch (roleName) {
      case 'presenter':
        setAvatar('icofont-presentation-alt')
        break
      case 'mentor':
        setTxtMessage('')
        setAvatar('icofont-teacher')
        break
      case 'cai':
        setTxtMessage('')
        setAvatar('cai')
    }
  }, [roleName])

  // Flow change behaviour
  useEffect(() => {
    if (flow !== CAI_CHAT_FLOWS.LIBRARIAN) setTxtMessage('')
  }, [flow])

  useEffect(() => {
    if (messages.length !== 1) return

    const fetchDaySentiment = async () => {
      const sentiment = await getDaySentiment(
        auth?.token,
        new Date().toISOString().split('T')[0],
        userInfo?.organization?.id,
      )
      if (sentiment === null) setShowMoodButton(true)
    }
    fetchDaySentiment()
  }, [messages])

  useEffect(() => {
    const chatHistory = document.getElementById('chatHistory')

    chatHistory?.addEventListener('scroll', handleUserScroll)

    // Cleanup event listener on component unmount
    return () => chatHistory?.removeEventListener('scroll', handleUserScroll)
  }, [])

  // Scroll to bottom of chat
  useLayoutEffect(() => {
    const chatHistory = document.getElementById('chatHistory')
    if (!isUserScrolling && chatHistory) {
      chatHistory.scrollTop = chatHistory.scrollHeight
    }
  }, [messages, isUserScrolling])

  if (flow === CAI_CHAT_FLOWS.LIBRARIAN) {
    return (
      <Librarian
        agentScope={agentScope}
        awaitingResponse={awaitingResponse}
        messages={messages}
        setFlow={setFlow}
        setMessagesMap={setMessagesMap}
        setTxtMessage={setTxtMessage}
        txtMessage={txtMessage}
        onChangeMessageText={onChangeMessageText}
        onMessage={onMessage}
      />
    )
  }

  if (flow === CAI_CHAT_FLOWS.FEEDBACK) {
    return (
      <Feedback
        awaitingResponse={awaitingResponse}
        flow={flow}
        initialFeedback={initialFeedback}
        initialFeedbackLoading={initialFeedbackLoading}
        messages={messages}
        newChat={newChat}
        setAwaitingResponse={setAwaitingResponse}
        setFlow={setFlow}
        setInitialFeedback={setInitialFeedback}
        setTxtMessage={setTxtMessage}
        txtMessage={txtMessage}
        onChangeMessageText={onChangeMessageText}
        onMessage={onMessage}
      />
    )
  }

  if (flow === CAI_CHAT_FLOWS.SESSION) {
    return (
      <Session
        awaitingResponse={awaitingResponse}
        messages={messages}
        setFlow={setFlow}
        setMessagesMap={setMessagesMap}
        setTxtMessage={setTxtMessage}
        txtMessage={txtMessage}
        onChangeMessageText={onChangeMessageText}
        onMessage={onMessage}
      />
    )
  }

  return (
    <div
      ref={agentChatRef}
      className="d-flex flex-column card card-chat-body border-0 order-1 w-100 position-relative h-100 p-3 gap-2 overflow-hidden"
      style={{
        background: 'var(--card-color)',
      }}
    >
      <TTSPlayer messages={messages} tourOpen={tourOpen} />

      <div className="h-100 position-relative d-flex justify-content-end align-items-start overflow-hidden flex-column">
        <MessagesHistory
          awaitingResponse={awaitingResponse}
          id={'chatHistory'}
          messages={messages}
          choicesElement={
            <ChatBubbleChoices
              bubblesLoading={bubblesLoading}
              currentBubbles={currentBubbles}
              fullScreen={fullScreen}
              handleMoodButtonClick={handleMoodButtonClick}
              messages={messages}
              moodButtonsEnabled={moodButtonsEnabled}
              showMoodButton={showMoodButton}
              onBubblesClick={onBubblesClick}
            />
          }
        />
      </div>

      <ChatInput
        awaitingResponse={awaitingResponse}
        setTxtMessage={setTxtMessage}
        txtMessage={txtMessage}
        onChangeMessageText={onChangeMessageText}
        onMessage={onMessage}
      />

      <ReportButton onReport={onReport} />
    </div>
  )
}

export default ChatAgent
