export const Roles = {
  employee: 'EMPLOYEE',
  manager: 'MANAGER',
  educator: 'EDUCATOR',
  hr: 'HR',
  it: 'IT',
}

export const LanguagesMapping: Record<string, string> = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
}

export const LanguageOptions = Object.keys(LanguagesMapping).map((key) => ({
  label: LanguagesMapping[key],
  value: key,
}))
