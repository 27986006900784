import { Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { validateNotEmpty } from './utils'

interface InputProps {
  required?: boolean
  source: string
  optionName?: string
  optionValue?: string
  disabled?: boolean
  validate?: (value: string[]) => string
  label?: string
  placeholder?: string
  autoSize?: Record<'minRows' | 'maxRows', number> | false
}

export const Input: FC<InputProps> = ({
  source,
  disabled = false,
  validate,
  label = '',
  required = false,
  placeholder = 'Please enter',
  autoSize = { minRows: 1, maxRows: 5 },
}) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <label className="form-label">
        {t(label)}
        {required && <span className="text-danger"> *</span>}
      </label>
      <Controller
        control={control}
        name={source}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <div>
              <TextArea
                autoSize={autoSize}
                disabled={disabled}
                placeholder={t(placeholder)}
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
              {error && <div style={{ color: 'red' }}>{error.message}</div>}
            </div>
          )
        }}
        rules={{
          validate: (value) => {
            if (required) {
              return validate ? validate(value) : validateNotEmpty(value)
            }
            return true
          },
        }}
      />
    </Space>
  )
}
