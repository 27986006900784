import { FC, useRef } from 'react'
import { Stack } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Message } from 'src/types/session.type'
import { BubbleChoices } from '../CaiPopup/Feedback/BubbleChoices'

interface ChatBubbleChoicesProps {
  fullScreen: boolean
  messages: Message[]
  currentBubbles: ButtonProp[]
  bubblesLoading: boolean
  onBubblesClick: (button: ButtonProp) => void
  showMoodButton: boolean
  moodButtonsEnabled: boolean
  handleMoodButtonClick: (id: string) => void
}

interface ButtonProp {
  id: string
  label: string
  scope: string
  message: string
  tooltip: string
  sub_buttons?: SubButtonProp[]
}

interface SubButtonProp {
  id: string
  label: string
  message: string
  tooltip: string
}

interface BubbleChoiceProp {
  buttons: ButtonProp[]
}

const ChatBubbleChoices: FC<ChatBubbleChoicesProps> = (props) => {
  const {
    fullScreen,
    messages,
    currentBubbles,
    bubblesLoading,
    onBubblesClick,
    showMoodButton,
    moodButtonsEnabled,
    handleMoodButtonClick,
  } = props
  const { t } = useTranslation()
  const bubblesContainerRef = useRef(null)

  return (
    <div
      className={
        fullScreen
          ? `d-flex gap-3 align-items-center`
          : 'd-flex flex-column gap-3'
      }
    >
      {!!messages.length &&
        (currentBubbles.length ? (
          <BubbleChoices<ButtonProp>
            ref={bubblesContainerRef}
            choices={currentBubbles}
            disabled={bubblesLoading}
            onClick={onBubblesClick}
          />
        ) : (
          <></>
        ))}
      {showMoodButton && moodButtonsEnabled && (
        <Stack
          className="user-info d-flex align-items-start"
          direction="horizontal"
          gap={3}
          style={{ width: 280, height: 40 }}
        >
          <div className="d-flex w-100 align-items-center gap-3">
            <span style={{ color: 'var(--text-color)' }}>
              {t('agent.chat.showMoodTitle', 'How are you feeling?')}
            </span>
            <span className="d-flex flex-row gap-3 agent-chat-mood-btn-groups">
              {[
                { id: 'grinning', icon: '😀' },
                { id: 'thinking', icon: '🤔' },
                { id: 'pensive', icon: '😔' },
              ].map((e) => (
                <button
                  key={e.id}
                  className="btn fs-5 agent-chat-mood-btn border-0 p-0"
                  onClick={() => handleMoodButtonClick(e.id)}
                >
                  {e.icon}
                </button>
              ))}
            </span>
          </div>
        </Stack>
      )}
    </div>
  )
}

export default ChatBubbleChoices
