import esES from 'antd/es/locale/es_ES'
import enUS from 'antd/locale/en_US'
import frFR from 'antd/locale/fr_FR'

export type Locale = 'en' | 'fr' | 'es'
export const antdLocales = {
  en: enUS,
  fr: frFR,
  es: esES,
}

export const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
  { value: 'es', label: 'Español' },
]
