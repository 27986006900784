import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export const formatDateTime = (time: string) => {
  const formats = ['YYYY-MM-DD h:mm A', 'h:mm A']
  let dateTime

  for (const format of formats) {
    const parsedDate = dayjs(time, format)
    if (parsedDate.isValid()) {
      dateTime = parsedDate.isBefore(dayjs(), 'day')
        ? parsedDate.format('DD/MM/YYYY h:mm A')
        : parsedDate.format('h:mm A')
      break
    }
  }

  return dateTime || ''
}
