import { t } from 'i18next'
import { FC } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  Category,
  GetCategoriesDocument,
  GetProgramCategoriesDocument,
  GetTaskCategoriesDocument,
  useSaveCategoryMutation,
} from 'src/gql/graphql'
import { Input, SelectInput } from 'src/components/inputs'

interface CategoryFormProps {
  onClose: () => void
  isEdit?: boolean
  record?: Category | null
}

const categoryOptions = [
  {
    value: 'PROGRAM',
    label: t('hr.assignTaskButton.form.program'),
  },
  {
    value: 'TASK',
    label: t('clients.programTitleEditable.task'),
  },
]

export const CategoryForm: FC<CategoryFormProps> = ({ onClose, record }) => {
  const { t } = useTranslation()
  const form = useForm<Category>({
    defaultValues: record || {},
  })

  const [
    saveCategory,
    { loading: saveCategoryLoading, error: saveCategoryError },
  ] = useSaveCategoryMutation()

  const onSave: SubmitHandler<Category> = async (data) => {
    const { id = '', name, type } = data
    try {
      await saveCategory({
        variables: {
          categoryId: id || undefined,
          input: {
            name,
            type,
          },
        },
        refetchQueries(result) {
          return [
            { query: GetProgramCategoriesDocument },
            { query: GetCategoriesDocument },
            { query: GetTaskCategoriesDocument },
          ]
        },
      })

      toast.success(
        record
          ? t(
              'resources.category.notification.updatedSuccess',
              'Category updated successfully.',
            )
          : t(
              'resources.category.notification.createdSuccess',
              'Category created successfully.',
            ),
      )

      onClose()
    } catch (error) {
      toast.error(t('common.toast.error'))
    }
  }

  return (
    <Modal centered show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">
          {t(record ? 'action.update' : 'action.create', {
            name: t('resources.category.name', 'Category'),
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSave)}>
            <div className="mb-3">
              <Input
                required
                label="common.word.name"
                source="name"
                placeholder={`${t(
                  'resources.category.fields.categoryName',
                )}...`}
              />
            </div>
            <div className="mb-3">
              <SelectInput
                required
                label="common.word.type"
                options={categoryOptions}
                source="type"
              />
            </div>
          </form>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onClose}>
          {t('common.button.cancel', 'Cancel')}
        </Button>
        <Button
          disabled={saveCategoryLoading}
          style={{ height: '43px' }}
          variant="primary "
          onClick={form.handleSubmit(onSave)}
        >
          {t(record ? 'action.update' : 'action.create', { name: '' })}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
