import { Icon } from '@iconify/react'
import { Form, Input, Modal, Tooltip } from 'antd'
import { Dispatch, FC, SetStateAction, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'src/components/Auth/AuthProvider'
import i18n from 'src/libs/i18n.lib'
import { submitFeedback } from 'src/utils/platform'
import FeedbackButtons from './FeedbackButtons'
import { FormInstance } from 'antd/lib'
import TextArea from 'antd/es/input/TextArea'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

interface FeedFwdModalProp {
  flow?: string
  currentFeedback: string
  setFlow: Dispatch<SetStateAction<string>>
  form: FormInstance
  setCurrentFeedback: Dispatch<SetStateAction<string>>
}

interface FormValue {
  id: string
  feedbackTarget: string
  targetId: string
}

const StyledFormItemLabel = styled.span`
  width: 70px; /* Set a fixed width for the labels */
  text-align: left;
`

const FeedFwdModal: FC<FeedFwdModalProp> = ({
  form,
  currentFeedback,
  setCurrentFeedback,
  setFlow,
}) => {
  const { userInfo, auth } = useContext(AuthContext)
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const targetIdVal = Form.useWatch('targetId', form)

  const handleFeedbackButtonsClick = async (values: FormValue) => {
    const { id, targetId, feedbackTarget } = values
    if (id !== 'anonymous' && id !== 'direct') return

    const senderId = userInfo?.stsUserId
    const targetType = feedbackTarget === 'person' ? 'user' : 'team'
    const feedback = currentFeedback
    const stsOrganisationId = userInfo?.organization?.stsOrganisationId

    if (id === 'anonymous') {
      await submitFeedback(
        auth?.token,
        senderId,
        targetId,
        targetType,
        feedback,
        true,
        i18n.language,
        stsOrganisationId,
      )
    } else {
      await submitFeedback(
        auth?.token,
        senderId,
        targetId,
        targetType,
        feedback,
        false,
        i18n.language,
        stsOrganisationId,
      )
    }
  }

  return (
    <>
      <Tooltip placement={'rightBottom'} title={t('common.alt.send')}>
        <button
          className="btn d-flex "
          disabled={!targetIdVal || !currentFeedback}
          style={{
            padding: 8,
            border: 0,
            borderRadius: '50%',
            background: 'var(--color-primary)',
          }}
        >
          <Icon
            color="white"
            height="18"
            icon="ri:mail-add-line"
            width="18"
            onClick={() => setOpen(true)}
          />
        </button>
      </Tooltip>
      <Modal
        centered
        open={open}
        title="Sending feedbacks"
        width={isMobile ? '100%' : '60%'}
        footer={
          <FeedbackButtons
            disabled={false}
            form={form}
            handleFeedbackButtonsClick={handleFeedbackButtonsClick}
            setFlow={setFlow}
          />
        }
        onCancel={() => setOpen(false)}
      >
        <Form form={form} name="control-hooks">
          <Form.Item
            name="targetName"
            label={
              <StyledFormItemLabel>{t('action.sendTo')}</StyledFormItemLabel>
            }
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={
              <StyledFormItemLabel>
                {t('common.word.feedback')}
              </StyledFormItemLabel>
            }
          >
            <TextArea
              value={currentFeedback}
              autoSize={{
                minRows: 5,
                maxRows: 5,
              }}
              style={{
                resize: 'none',
              }}
              onChange={(e) => setCurrentFeedback(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default FeedFwdModal
