import {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import MessagesHistory from 'src/components/ChatAgent/MessagesHistory'
import { FEEDBACK_CHOICES, FeedbackChoice } from 'src/constants/feedFwd.contant'
import FeedFwdCanvas from './FeedFwdCanvas'

import { BubbleChoices } from './BubbleChoices'
import { Dropdown, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { ChatInput } from 'src/components/inputs/ChatInput'

interface FeedBackProps {
  flow?: string
  setFlow: Dispatch<SetStateAction<string>>
  initialFeedback: string
  initialFeedbackLoading: unknown
  awaitingResponse: boolean
  onMessage: (msg: string, hidden: boolean) => void
  onChangeMessageText: (str: string) => void
  setAwaitingResponse: unknown
  txtMessage: string
  setTxtMessage: Dispatch<SetStateAction<string>>
  messages: Message[]
  newChat: (selectedFlow?: string) => Promise<void>
  setInitialFeedback: Dispatch<SetStateAction<string>>
}

type Message = {
  message: string
  type: 'received' | 'sent'
  owner: string
  time: string
  images: string[]
}
const primaryColor = '#681EC2'

const Feedback: FC<FeedBackProps> = (props) => {
  const {
    flow,
    setFlow,
    initialFeedback,
    setInitialFeedback,
    initialFeedbackLoading,
    txtMessage,
    setTxtMessage,
    awaitingResponse,
    setAwaitingResponse,
    onMessage,
    onChangeMessageText,
    messages,
    newChat,
  } = props

  const { t } = useTranslation()
  const [currentFeedback, setCurrentFeedback] =
    useState<string>(initialFeedback)
  const [loading, setLoading] = useState(initialFeedbackLoading)
  const [bubbleChoices, setBubbleChoices] = useState(FEEDBACK_CHOICES.buttons)
  const [firstDivHeight, setFirstDivHeight] = useState(80)
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [showSuggestions, setShowSuggestions] = useState(true)

  const handleMouseDownFirst = () => {
    setIsDragging(true)
  }

  const handleMouseMove = (e: MouseEvent) => {
    const totalHeight = window.innerHeight
    const minHeightPx = 300 // Minimum height in pixels
    const minHeightPercentage = (minHeightPx / totalHeight) * 100

    if (isDragging) {
      const newHeight = Number(((e.clientY / totalHeight) * 100).toFixed(2))
      if (newHeight >= minHeightPercentage && newHeight <= 80) {
        setFirstDivHeight(newHeight)
      }
    }
  }

  const handleMouseUp = (): void => {
    setIsDragging(false)
  }

  const handleMenuItemClick = (message: string) => {
    onMessage(message, false)
  }

  const items = [
    {
      key: '1',
      label: (
        <div
          className="d-flex  align-items-center"
          onClick={() =>
            handleMenuItemClick(t('resources.feedbacks.request.explain'))
          }
        >
          <Icon
            height="20"
            icon="material-symbols-light:help-clinic"
            style={{ color: primaryColor }}
            width="20"
          />
          <div>{t('common.word.explain')} </div>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          className="d-flex  align-items-center"
          onClick={() =>
            handleMenuItemClick(t('resources.feedbacks.request.help'))
          }
        >
          <Icon
            height="20"
            icon="material-symbols-light:help-outline"
            style={{ color: primaryColor }}
            width="20"
          />
          <div>{t('common.word.help')} </div>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div
          className="d-flex  align-items-center"
          onClick={() =>
            handleMenuItemClick(t('resources.feedbacks.request.example'))
          }
        >
          <Icon
            height="20"
            icon="material-symbols-light:demography"
            style={{ color: primaryColor }}
            width="20"
          />
          <div>{t('common.word.example')} </div>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div
          className="d-flex align-items-center"
          onClick={() => setShowSuggestions(!showSuggestions)}
        >
          <Icon
            height="20"
            icon="icons8:idea"
            style={{ color: primaryColor }}
            width="20"
          />
          <div>{t('common.word.suggestions')} </div>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    } else {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging])

  useEffect(() => {
    setCurrentFeedback(initialFeedback)
  }, [initialFeedback])

  useEffect(() => {
    setLoading(initialFeedbackLoading)
  }, [initialFeedbackLoading])

  useEffect(() => {
    if (messages.length === 1) {
      setBubbleChoices(FEEDBACK_CHOICES.buttons)
    }
    const element = document.getElementById('fwdChatHistory')
    if (element) {
      const timer = setTimeout(() => {
        element.scrollTop = element.scrollHeight
      }, 200)

      return () => clearTimeout(timer)
    }
  }, [messages])

  useEffect(() => {
    if (initialFeedback.length > 300) {
      setFirstDivHeight(60)
    }
  }, [initialFeedback])

  return (
    <div
      className="d-flex flex-column card card-chat-body border-0  w-100 h-100 gap-2 overflow-hidden"
      style={{
        backgroundColor: 'var(--feedback-color)',
        paddingBottom: '0 !important',
      }}
    >
      <div
        className="d-flex position-relative"
        style={{
          overflow: 'hidden',
          height: `${firstDivHeight}%`,
          maxHeight: `${firstDivHeight}%`,
        }}
      >
        <div
          className="resizer"
          id="feedFwdResizer"
          style={{
            width: '100%',
            bottom: 0,
            left: 0,
            position: 'absolute',
            height: '15px',
            opacity: 0.5, // Adjust opacity to make the icon visible
            cursor: 'col-resize',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'var(--bg-chat-body)',
          }}
          onMouseDown={handleMouseDownFirst}
        >
          <Icon height="24" icon="pepicons-pop:line-x" width="24" />
        </div>
        <div
          className="flex-grow-1 rounded-3 w-100"
          style={{ backgroundColor: 'var(--bg-chat-body)' }}
        >
          <div
            className={`d-flex p-3 h-100 align-items-start flex-column ${isDragging ? 'user-select-none' : ''}`}
          >
            <MessagesHistory
              awaitingResponse={awaitingResponse}
              id="fwdChatHistory"
              messages={messages}
              choicesElement={
                <div>
                  {showSuggestions && bubbleChoices.length ? (
                    <Space
                      style={{
                        height: 'auto',
                        background: '#F9F8FF',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        padding: '2px',
                        cursor: awaitingResponse ? 'not-allowed' : 'pointer',
                      }}
                    >
                      <BubbleChoices<FeedbackChoice>
                        choices={bubbleChoices}
                        setBubbleChoices={setBubbleChoices}
                        onMessage={onMessage}
                      />
                    </Space>
                  ) : null}
                </div>
              }
            />
            <ChatInput
              awaitingResponse={awaitingResponse}
              newChat={newChat}
              setTxtMessage={setTxtMessage}
              txtMessage={txtMessage}
              // choicesElement={
              //   <FeedFwdChoices
              //     choices={bubbleChoices}
              //     setBubbleChoices={setBubbleChoices}
              //     onMessage={onMessage}
              //   />
              // }
              prefix={
                <Dropdown
                  disabled={awaitingResponse}
                  menu={{ items }}
                  placement="topLeft"
                >
                  <PlusOutlined
                    style={{
                      color: primaryColor,
                      marginRight: '2px',
                      cursor: 'pointer',
                    }}
                  />
                </Dropdown>
              }
              onChangeMessageText={onChangeMessageText}
              onMessage={onMessage}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          height: `${100 - firstDivHeight}%`,
          maxHeight: `${100 - firstDivHeight}%`,
          marginBottom: '5px',
        }}
      >
        <FeedFwdCanvas
          awaitingResponse={awaitingResponse}
          currentFeedback={currentFeedback}
          flow={flow}
          handleMouseDownFirst={handleMouseDownFirst}
          initialFeedback={initialFeedback}
          setCurrentFeedback={setCurrentFeedback}
          setFlow={setFlow}
          setInitialFeedback={setInitialFeedback}
        />
      </div>
    </div>
  )
}

export default memo(Feedback)
