import { t } from 'i18next'

const validateNotEmpty = (value: unknown): string | true => {
  if (!value) return t('error_messages.isRequired')

  if (value instanceof FileList) {
    if (value.length === 0) {
      return t('error_messages.isRequired')
    }
    return true
  }

  if (Array.isArray(value)) {
    if (value.length === 0) {
      return t('error_messages.isRequired')
    }
    return true
  }

  if (typeof value === 'string' && value.trim() === '') {
    return t('error_messages.isRequired')
  }

  return true
}

export { validateNotEmpty }
