import React from 'react'

export const usePagination = () => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(10)

  const handleRowsPerPageChange = (newRowsPerPage) =>
    setPageSize(newRowsPerPage)

  const handlePageChange = (newPage) => setCurrentPage(newPage)

  return { currentPage, pageSize, handleRowsPerPageChange, handlePageChange }
}
