import { useEffect, useState, Suspense } from 'react'
import { Spinner } from 'react-bootstrap'
import { Switch, useLocation, withRouter } from 'react-router-dom'
import './App.css'
import ProtectedRoute from './components/Auth/ProtectedRoute'
import Sidebar from './components/Sidebar'
import CaiPopupProvider from './context/CaiPopupContext'
import NotificationProvider from './context/NotificationContext'
import AuthIndex from './screens/AuthIndex'
import MainIndex from './screens/MainIndex'
import OAuthIndex from './screens/OAuthIndex'

const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 vw-100">
      <Spinner />
    </div>
  )
}

export default withRouter(function App(props) {
  const { pathname } = useLocation()

  function activeKey() {
    var res = window.location.pathname
    var baseUrl = process.env.PUBLIC_URL
    baseUrl = baseUrl.split('/')
    res = res.split('/')
    res = res.length ? res[baseUrl.length] : '/'
    res = res ? '/' + res : '/'
    const activeKey1 = res
    return activeKey1
  }

  const isActiveKey = [
    '/sign-in',
    '/sign-up',
    '/password-reset',
    '/password-reset-confirmation',
    '/2-step-authentication',
  ].includes(activeKey())

  if (pathname.includes('/oauth')) {
    return (
      <div className="theme-indigo" id="mytask-layout">
        <Switch>
          <OAuthIndex />
        </Switch>
      </div>
    )
  }

  if (isActiveKey)
    return (
      <div className="theme-indigo" id="mytask-layout">
        <Switch>
          <AuthIndex />
        </Switch>
      </div>
    )

  return (
    <div className="theme-indigo" id="mytask-layout">
      <CaiPopupProvider>
        <NotificationProvider>
          <Suspense fallback={<Loading />}>
            <Sidebar activeKey={activeKey()} history={props.history} />
            <Switch>
              <ProtectedRoute
                activeKey={activeKey()}
                component={MainIndex}
                path="/"
              />
            </Switch>
          </Suspense>
        </NotificationProvider>
      </CaiPopupProvider>
    </div>
  )
})
