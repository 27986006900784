import { FC } from 'react'
import styled from 'styled-components'

interface ButtonProps {
  disabled?: boolean
  label: string
  style?: React.CSSProperties
  onClick?: () => void
  icon?: React.ReactNode
}

const StyledButton = styled.button`
  background-color: var(--color-primary);
  color: var(--white-color);
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

export const Button: FC<ButtonProps> = (props) => {
  const { label, icon, ...rest } = props
  return (
    <StyledButton {...rest}>
      <span>{icon}</span>
      {label}
    </StyledButton>
  )
}
