import {
  FC,
  ReactElement,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import { isMobileOnly } from 'react-device-detect'
import CAIIcon from '../CaiIcon'
import CAIIconThinking from '../CaiIcon/CAIIconThinking'
import ChatBubble from './ChatBubble'
import { formatDateTime } from './utils'
import { Message } from 'src/types/session.type'

interface MessageHistoryProps {
  id: string
  messages: Message[]
  awaitingResponse: boolean
  choicesElement?: ReactElement | null | false
  style?: React.CSSProperties
}

const MessagesHistory: FC<MessageHistoryProps> = ({
  id,
  messages = [],
  awaitingResponse,
  choicesElement,
  style,
}) => {
  const [avatar, setAvatar] = useState('cai')
  const listRef = useRef<HTMLUListElement>(null)
  const choicesRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const listElement = listRef.current
    if (listElement) {
      const lastChild =
        choicesElement && choicesRef.current
          ? choicesRef.current
          : listElement.lastElementChild

      if (lastChild) {
        lastChild.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }
  }, [messages, choicesElement])

  return (
    <div
      className="w-100 h-100"
      id={id}
      style={{
        transition: `top ease 0.3s`,
        overflow: 'auto',
        ...style,
      }}
    >
      <ul
        ref={listRef}
        className="custchat-history list-unstyled mb-0 flex-grow-1  mh-100 overflow-auto "
      >
        {messages.map((data, i) => {
          const dateTime = formatDateTime(data.time)

          if (!data.message) return
          return (
            <li
              key={i}
              className={
                data.type === 'received'
                  ? 'mb-2 d-flex flex-row align-items-end'
                  : 'mb-4 d-flex flex-row-reverse align-items-end'
              }
            >
              <div
                style={{ maxWidth: isMobileOnly ? '85%' : '75%' }}
                className={`d-flex flex-column ${
                  data.type === 'received' ? '' : 'align-items-end text-end'
                }`}
              >
                <div
                  className={`d-flex gap-2 user-info mb-1 align-items-center ${
                    data.type !== 'received'
                      ? 'justify-content-end'
                      : 'justify-content-start'
                  } `}
                >
                  {data.type === 'received' &&
                    (avatar === 'cai' ? (
                      <CAIIcon isCai style={{ width: 20, height: 20 }} />
                    ) : (
                      <i className={avatar}></i>
                    ))}

                  <div
                    className="text-capitalize  "
                    style={{
                      fontSize: 12,
                      color: 'var(--color-on-surface)',
                    }}
                  >
                    {data.owner === 'user' ? 'you' : data.owner || 'Agent'}
                  </div>

                  <div className="text-muted small">{dateTime}</div>
                </div>

                <ChatBubble data={data} />
              </div>
            </li>
          )
        })}
        {awaitingResponse ? (
          <CAIIconThinking awaitingResponse={awaitingResponse} />
        ) : choicesElement && isValidElement(choicesElement) ? (
          <div ref={choicesRef}>{cloneElement(choicesElement)}</div>
        ) : null}
      </ul>
    </div>
  )
}

export default MessagesHistory
