import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Tooltip from '../UI/Tooltip'

type Props = {
  disabled?: boolean
  onDelete?: () => void
  modalHeader?: string
  modalBody?: string
  tooltipMessage?: string
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>
}

function DeleteButton(props: Props) {
  const { t } = useTranslation()
  const {
    disabled,
    onDelete,
    modalHeader,
    modalBody,
    tooltipMessage,
    buttonProps,
  } = props

  const [open, setOpen] = React.useState(false)

  const Wrapper = tooltipMessage ? Tooltip : React.Fragment

  const handleOpen = () => setOpen((state) => !state)

  const handleDelete = () => {
    handleOpen()
    onDelete?.()
  }

  return (
    <>
      <Wrapper {...(tooltipMessage && { message: tooltipMessage })}>
        <button
          className="btn btn-outline-secondary"
          {...buttonProps}
          onClick={handleOpen}
        >
          <i className="icofont-ui-delete text-danger"></i>
        </button>
      </Wrapper>

      {open && (
        <Modal size="sm" show={open} onHide={handleOpen} centered>
          <Modal.Header>
            <Modal.Title>{modalHeader}</Modal.Title>
          </Modal.Header>

          <Modal.Body>{modalBody}</Modal.Body>

          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleOpen}>
              {t('common.button.cancel', 'Cancel')}
            </button>
            <button
              disabled={disabled}
              className="btn btn-danger"
              onClick={handleDelete}
            >
              {t('common.button.confirm', 'Confirm')}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default DeleteButton
